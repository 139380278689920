<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin log'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row" v-if="!onLoading">
              <h5 class="bold-text">Thông tin log</h5>
              <hr />

              <b-container class="bv-example-row">
                <b-row class="justify-content-md-center mb-10">
                  <b-col>
                    <b-row>
                      <b-col class="col-md-3">
                        <p class="header-color">Loại log:</p>
                        <p class="header-color">Mã:</p>
                      </b-col>
                      <b-col>
                        <p>{{ type }}</p>
                        <p>{{ id }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="col-md-3">
                        <p class="header-color">Người sửa:</p>
                      </b-col>
                      <b-col>
                        <p>{{ updatedBy.fullName }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-row>
                      <b-col class="col-md-3">
                        <p class="header-color">Cửa hàng:</p>
                      </b-col>
                      <b-col>
                        <p>{{ storeName }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>

              <h5 class="bold-text">Thông tin thay đổi</h5>
              <hr />
              <b-container class="bv-example-row">
                <table
                  border="1"
                  style="width: 60%"
                  class="table table-bordered table-vertical-center table-hover mb-10"
                >
                  <colgroup>
                    <col style="width: 20%" />
                    <col style="width: 40%" />
                    <col style="width: 40%" />
                  </colgroup>
                  <thead class="bg-secondary">
                    <tr>
                      <th rowspan="2">Thông tin trên phiếu</th>
                    </tr>
                    <tr>
                      <th>Trước khi sửa</th>
                      <th>Sau khi sửa</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="diffInfo._.customerName.status === 'MODIFIED'">
                      <th class="header-color">Họ tên khách hàng</th>
                      <td>{{ diffInfo._.customerName.original }}</td>
                      <td>{{ diffInfo._.customerName.current }}</td>
                    </tr>
                    <tr v-show="diffInfo._.customerPhone.status === 'MODIFIED'">
                      <th class="header-color">SĐT khách hàng</th>
                      <td>{{ diffInfo._.customerPhone.original }}</td>
                      <td>{{ diffInfo._.customerPhone.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.cashAccountName.status)">
                      <th class="header-color">
                        Tài khoản thanh toán tiền mặt
                      </th>
                      <td>{{ diffInfo._.cashAccountName.original }}</td>
                      <td>{{ diffInfo._.cashAccountName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.cashAmount.status)">
                      <th class="header-color">Tiền mặt</th>
                      <td>{{ formatMoney(diffInfo._.cashAmount.original) }}</td>
                      <td>{{ formatMoney(diffInfo._.cashAmount.current) }}</td>
                    </tr>
                    <tr
                      v-show="isModified(diffInfo._.transferAccountName.status)"
                    >
                      <th class="header-color">Tài khoản chuyển khoản</th>
                      <td>{{ diffInfo._.transferAccountName.original }}</td>
                      <td>{{ diffInfo._.transferAccountName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.transferAmount.status)">
                      <th class="header-color">Tiền chuyển khoản</th>
                      <td>
                        {{ formatMoney(diffInfo._.transferAmount.original) }}
                      </td>
                      <td>
                        {{ formatMoney(diffInfo._.transferAmount.current) }}
                      </td>
                    </tr>
                    <tr
                      v-show="isModified(diffInfo._.transferAccountCode.status)"
                    >
                      <th class="header-color">Mã chuyển khoản</th>
                      <td>{{ diffInfo._.transferAccountCode.original }}</td>
                      <td>{{ diffInfo._.transferAccountCode.current }}</td>
                    </tr>
                    <tr
                      v-show="
                        isModified(diffInfo._.transferReferenceCode.status)
                      "
                    >
                      <th class="header-color">Mã tham chiếu</th>
                      <td>{{ diffInfo._.transferReferenceCode.original }}</td>
                      <td>{{ diffInfo._.transferReferenceCode.current }}</td>
                    </tr>
                    <!-- <tr
                      v-show="isModified(diffInfo._.customerTransferNo.status)"
                    >
                      <th class="header-color">Tài khoản ck của khách:</th>
                      <td>{{ diffInfo._.customerTransferNo.original }}</td>
                      <td>{{ diffInfo._.customerTransferNo.current }}</td>
                    </tr> -->
                    <tr
                      v-show="isModified(diffInfo._.creditAccountName.status)"
                    >
                      <th class="header-color">Tài khoản quẹt thẻ</th>
                      <td>{{ diffInfo._.creditAccountName.original }}</td>
                      <td>{{ diffInfo._.creditAccountName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.creditAmount.status)">
                      <th class="header-color">Tiền quẹt thẻ</th>
                      <td>
                        {{ formatMoney(diffInfo._.creditAmount.original) }}
                      </td>
                      <td>
                        {{ formatMoney(diffInfo._.creditAmount.current) }}
                      </td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.creditCode.status)">
                      <th class="header-color">Mã quẹt thẻ</th>
                      <td>{{ diffInfo._.creditCode.original }}</td>
                      <td>{{ diffInfo._.creditCode.current }}</td>
                    </tr>
                    <!-- <tr
                      v-show="isModified(diffInfo._.creditFeeAcountName.status)"
                    >
                      <th class="header-color">Tài khoản thu phí quẹt thẻ</th>
                      <td>{{ diffInfo._.creditFeeAcountName.original }}</td>
                      <td>{{ diffInfo._.creditFeeAcountName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.otherFees.status)">
                      <th class="header-color">Phí quẹt thẻ</th>
                      <td>
                        {{ formatMoney(diffInfo._.otherFees.original) }}
                      </td>
                      <td>
                        {{ formatMoney(diffInfo._.otherFees.current) }}
                      </td>
                    </tr> -->
                    <tr v-show="isModified(diffInfo._.creditCardNo.status)">
                      <th class="header-color">4 số cuối thẻ</th>
                      <td>{{ diffInfo._.creditCardNo.original }}</td>
                      <td>{{ diffInfo._.creditCardNo.current }}</td>
                    </tr>
                    <!-- <tr
                      v-show="
                        isModified(diffInfo._.payCreditFeeTypeName.status)
                      "
                    >
                      <th class="header-color">
                        Hình thức thanh toán phí quẹt thẻ
                      </th>
                      <td>{{ diffInfo._.payCreditFeeTypeName.original }}</td>
                      <td>{{ diffInfo._.payCreditFeeTypeName.current }}</td>
                    </tr> -->
                    <tr
                      v-show="
                        isModified(diffInfo._.installMoneyAccountName.status)
                      "
                    >
                      <th class="header-color">Dịch vụ trả góp</th>
                      <td>{{ diffInfo._.installMoneyAccountName.original }}</td>
                      <td>{{ diffInfo._.installMoneyAccountName.current }}</td>
                    </tr>
                    <tr
                      v-show="
                        isModified(diffInfo._.installedMoneyAmount.status)
                      "
                    >
                      <th class="header-color">Tiền trả góp</th>
                      <td>
                        {{
                          formatMoney(diffInfo._.installedMoneyAmount.original)
                        }}
                      </td>
                      <td>
                        {{
                          formatMoney(diffInfo._.installedMoneyAmount.current)
                        }}
                      </td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.installMoneyCode.status)">
                      <th class="header-color">Mã hợp đồng trả góp</th>
                      <td>{{ diffInfo._.installMoneyCode.original }}</td>
                      <td>{{ diffInfo._.installMoneyCode.current }}</td>
                    </tr>
                    <tr
                      v-show="
                        isModified(diffInfo._.installMoneyAccountCode.status)
                      "
                    >
                      <th class="header-color">Mã hợp đồng</th>
                      <td>{{ diffInfo._.installMoneyAccountCode.original }}</td>
                      <td>{{ diffInfo._.installMoneyAccountCode.current }}</td>
                    </tr>
                    <!-- <tr
                      v-show="
                        isModified(diffInfo._.installmentProgramMonthNo.status)
                      "
                    >
                      <th class="header-color">Kì hạn</th>
                      <td>
                        {{ diffInfo._.installmentProgramMonthNo.original }}
                      </td>
                      <td>
                        {{ diffInfo._.installmentProgramMonthNo.current }}
                      </td>
                    </tr> -->

                    <!-- <tr
                      v-show="
                        isModified(
                          diffInfo._.installmentProgramPrepayPercent.status,
                        )
                      "
                    >
                      <th class="header-color">Phần trăm trả trước</th>
                      <td>
                        {{
                          diffInfo._.installmentProgramPrepayPercent.original
                        }}
                      </td>
                      <td>
                        {{ diffInfo._.installmentProgramPrepayPercent.current }}
                      </td>
                    </tr> -->
                    <!-- <tr v-show="isModified(diffInfo._.conversionFee.status)">
                      <th class="header-color">Phí chuyển đổi</th>
                      <td>{{ diffInfo._.conversionFee.original }}</td>
                      <td>{{ diffInfo._.conversionFee.current }}</td>
                    </tr> -->
                    <tr
                      v-show="isModified(diffInfo._.customerIndentifyNo.status)"
                    >
                      <th class="header-color">Số CMND khách hàng</th>
                      <td>{{ diffInfo._.customerIndentifyNo.original }}</td>
                      <td>{{ diffInfo._.customerIndentifyNo.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.discountAmount.status)">
                      <th class="header-color">Chiết khấu</th>
                      <td>
                        {{ formatMoney(diffInfo._.discountAmount.original) }}
                      </td>
                      <td>
                        {{ formatMoney(diffInfo._.discountAmount.current) }}
                      </td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.discountType.status)">
                      <th class="header-color">Kiểu chiết khấu</th>
                      <td>{{ diffInfo._.discountType.original }}</td>
                      <td>{{ diffInfo._.discountType.current }}</td>
                    </tr>
                    <!-- <tr v-show="isModified(diffInfo._.orderSourceName.status)">
                      <th class="header-color">Nguồn đơn hàng</th>
                      <td>{{ diffInfo._.orderSourceName.original }}</td>
                      <td>{{ diffInfo._.orderSourceName.current }}</td>
                    </tr> -->
                    <tr v-show="isModified(diffInfo._.saleName.status)">
                      <th class="header-color">NV bán hàng</th>
                      <td>{{ diffInfo._.saleName.original }}</td>
                      <td>{{ diffInfo._.saleName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.technicalName.status)">
                      <th class="header-color">NV kỹ thuật</th>
                      <td>{{ diffInfo._.technicalName.original }}</td>
                      <td>{{ diffInfo._.technicalName.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.cashierName.status)">
                      <th class="header-color">NV thu ngân</th>
                      <td>{{ diffInfo._.cashierName.original }}</td>
                      <td>{{ diffInfo._.cashierName.current }}</td>
                    </tr>
                    <tr v-show="diffInfo._.customerNote.status === 'MODIFIED'">
                      <th class="header-color">Ghi chú khách hàng</th>
                      <td>{{ diffInfo._.customerNote.original }}</td>
                      <td>{{ diffInfo._.customerNote.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.saleNote.status)">
                      <th class="header-color">Ghi chú nội bộ</th>
                      <td>{{ diffInfo._.saleNote.original }}</td>
                      <td>{{ diffInfo._.saleNote.current }}</td>
                    </tr>
                    <tr v-show="isModified(diffInfo._.warrantyNote.status)">
                      <th class="header-color">Ghi chú bảo hành</th>
                      <td>{{ diffInfo._.warrantyNote.original }}</td>
                      <td>{{ diffInfo._.warrantyNote.current }}</td>
                    </tr>
                    <!-- <tr
                      v-show="
                        isModified(diffInfo._.warrantyCustomerName.status)
                      "
                    >
                      <th class="header-color">KH bảo hành</th>
                      <td>{{ diffInfo._.warrantyCustomerName.original }}</td>
                      <td>{{ diffInfo._.warrantyCustomerName.current }}</td>
                    </tr> -->
                    <!-- <tr
                      v-show="
                        isModified(diffInfo._.warrantyCustomerPhone.status)
                      "
                    >
                      <th class="header-color">SĐT người bảo hành</th>
                      <td>{{ diffInfo._.warrantyCustomerPhone.original }}</td>
                      <td>{{ diffInfo._.warrantyCustomerPhone.current }}</td>
                    </tr> -->
                  </tbody>
                </table>
              </b-container>
              <h5 class="bold-text">Chi tiết thay đổi sản phẩm</h5>
              <hr />
              <b-container class="bv-example-row">
                <table
                  class="table table-bordered table-vertical-center table-hover mb-10"
                >
                  <tr>
                    <th style="width: 50%">Trước</th>
                    <th>Sau</th>
                  </tr>
                  <tr v-for="(item, index) in diffItem._" :key="index">
                    <td>
                      <div
                        v-if="item.status === 'ADDED'"
                        v-show="item.status === 'ADDED'"
                      >
                        <span>{{ item.original }}</span>
                      </div>

                      <div>
                        <span
                          v-if="item.status === 'MODIFIED'"
                          v-show="item.status === 'MODIFIED'"
                        >
                          <p class="text-primary header-color">
                            {{ item._.productName.original }}
                          </p>
                          <b-row>
                            <b-col class="col col-sm-3">
                              <p class="header-color">ID sản phẩm:</p>
                              <p class="header-color">SK - SM / Seri :</p>
                              <p class="header-color">Giá:</p>
                              <p class="header-color">Số lượng:</p>
                              <p class="header-color">Chiết khấu:</p>
                            </b-col>
                            <b-col>
                              <p>{{ item._.productId.original || 0 }}</p>
                              <p>
                                <template
                                  v-if="
                                    item._.chassisNumber.current &&
                                    item._.engineNumber.current
                                  "
                                >
                                  <span class="mr-3 text-primary"
                                    >SK:{{
                                      ' ' + item._.chassisNumber.current || null
                                    }}</span
                                  >
                                  <span class="text-primary"
                                    >SM:
                                    {{
                                      ' ' + item._.chassisNumber.current || null
                                    }}</span
                                  >
                                </template>
                                <template v-if="item._.serialNumber.current">
                                  <span class="text-primary"
                                    >SM:{{
                                      ' ' + item._.serialNumber.current || null
                                    }}</span
                                  ></template
                                >
                                <template
                                  v-if="
                                    !item._.chassisNumber.current &&
                                    !item._.engineNumber.current &&
                                    !item._.serialNumber.current
                                  "
                                >
                                  <span>-</span></template
                                >
                              </p>
                              <p>
                                {{ convertPrice(item._.productPrice.original) }}
                              </p>
                              <p>{{ item._.quantity.original }}</p>
                              <p>{{ item._.discountAmount.original }}</p>
                            </b-col>
                          </b-row>
                        </span>
                      </div>
                    </td>

                    <td>
                      <div
                        v-if="item.status === 'ADDED'"
                        v-show="item.status === 'ADDED'"
                      >
                        <p class="text-primary header-color">
                          {{ item.current.productName }}
                        </p>
                        <b-row>
                          <b-col class="col col-sm-3">
                            <p class="header-color">ID sản phẩm:</p>
                            <p class="header-color">SK - SM / Seri:</p>
                            <p class="header-color">Giá:</p>
                            <p class="header-color">Số lượng:</p>
                            <p class="header-color">Chiết khấu:</p>
                          </b-col>
                          <b-col>
                            <p>{{ item.current.productId }}</p>
                            <p>
                              <template
                                v-if="
                                  item.current.chassisNumber &&
                                  item.current.engineNumber
                                "
                              >
                                <span class="mr-3 text-primary"
                                  >SK:{{
                                    ' ' + item.current.chassisNumber || null
                                  }}</span
                                >
                                <span class="text-primary"
                                  >SM:
                                  {{
                                    ' ' + item.current.chassisNumber || null
                                  }}</span
                                >
                              </template>
                              <template v-if="item.current.serialNumber">
                                <span class="text-primary"
                                  >SM:{{
                                    ' ' + item.current.serialNumber || null
                                  }}</span
                                ></template
                              >
                              <template
                                v-if="
                                  !item.current.chassisNumber &&
                                  !item.current.engineNumber &&
                                  !item.current.serialNumber
                                "
                              >
                                <span>-</span></template
                              >
                            </p>
                            <p>{{ convertPrice(item.current.productPrice) }}</p>
                            <p>{{ item.current.quantity }}</p>
                            <p>{{ item.current.discountAmount }}</p>
                          </b-col>
                        </b-row>
                      </div>

                      <div>
                        <span v-if="item.status === 'MODIFIED'">
                          <p class="text-primary header-color">
                            {{ item._.productName.current }}
                          </p>
                          <b-row>
                            <b-col class="col col-sm-3">
                              <p class="header-color">ID sản phẩm:</p>
                              <p class="header-color">SK - SM / Seri :</p>
                              <p class="header-color">Giá:</p>
                              <p class="header-color">Số lượng:</p>
                              <p class="header-color">Chiết khấu:</p>
                            </b-col>
                            <b-col>
                              <p>{{ item._.productId.current }}</p>
                              <p>
                                <template
                                  v-if="
                                    item._.chassisNumber.current &&
                                    item._.engineNumber.current
                                  "
                                >
                                  <span class="mr-3 text-primary"
                                    >SK:{{
                                      ' ' + item._.chassisNumber.current || null
                                    }}</span
                                  >
                                  <span class="text-primary"
                                    >SM:
                                    {{
                                      ' ' + item._.chassisNumber.current || null
                                    }}</span
                                  >
                                </template>
                                <template v-if="item._.serialNumber.current">
                                  <span class="text-primary"
                                    >SM:{{
                                      ' ' + item._.serialNumber.current || null
                                    }}</span
                                  ></template
                                >
                                <template
                                  v-if="
                                    !item._.chassisNumber.current &&
                                    !item._.engineNumber.current &&
                                    !item._.serialNumber.current
                                  "
                                >
                                  <span>-</span></template
                                >
                              </p>

                              <p>
                                {{ convertPrice(item._.productPrice.current) }}
                              </p>
                              <p>{{ item._.quantity.current }}</p>
                              <p>{{ item._.discountAmount.current }}</p>
                            </b-col>
                          </b-row>
                        </span>
                      </div>
                    </td>
                  </tr>
                </table>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <!-- <b-col lg="4" class="pb-2"> -->
            <router-link to="/bill-history" tag="button">
              <b-button
                style="font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      id: '',
      type: '',
      updatedBy: '',
      storeName: '',
      diffInfo: {},
      diffItem: {},
      clientIp: '',
      onLoading: true,
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Lịch sử hóa đơn', route: '/bill-history' },
      { title: 'Chi tiết lịch sử hóa đơn', route: '/detail-bill-history' },
    ]);

    this.id = this.$route.query.id;
    this.getDetailHistory();
  },
  created() {},
  methods: {
    convertPrice: function (number) {
      let result = new Intl.NumberFormat('vn-VN').format(number);
      return isNaN(parseFloat(result)) ? '' : result;
    },
    setCurrentAddedObj(obj) {
      let result = JSON.parse(obj);
      return result;
    },
    isModified(value) {
      return value === 'MODIFIED';
    },
    getHistoryType(typeId) {
      switch (typeId) {
        case 1:
          return 'Đơn hàng';
        case 2:
          return 'Hóa đơn';
        default:
          return '';
      }
    },
    getDetailHistory: async function () {
      this.onLoading = true;
      await ApiService.setHeader();
      await ApiService.query(`bills/${this.id}/history-detail/`).then((res) => {
        let { updatedBy, storeName, diffInfo, diffItem, clientIp } =
          res.data.data;
        this.type = 'Đơn hàng';
        this.updatedBy = updatedBy;
        this.storeName = storeName;
        this.clientIp = clientIp;
        this.diffInfo = diffInfo;
        this.diffItem = diffItem;
        this.onLoading = false;
      });
      this.onLoading = false;
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
  },
};
</script>

<style>
.header-color {
  font-weight: 500;
}

.bold-text {
  color: #212121;
}
</style>
